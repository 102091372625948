import axios from "axios";
import { ref } from "vue";
import { useUserStore } from "~/store/user";
import { navigateTo } from "#app";

export function useUserAuth() {
    // Data
    const config = useRuntimeConfig();
    const errorMsg = ref("");
    const processing = ref(false);

    // Function
    async function login(email = "", password = "", redirectUrl = "") {
        errorMsg.value = "";
        try {
            processing.value = true;
            const response = await axios.post(config.public.STATAMIC_API + "/login", {
                email: email,
                password: password,
            });

            if (response.status !== 200 || !response.data) {
                throw "Failed to login";
            }

            useUserStore().updateUser(response.data);

            if (redirectUrl) {
                navigateTo(redirectUrl);
            }

            const router = useRouter();
            router.back();
        } catch (e: any) {
            console.log("e:", e);
            if (e.response?.status == 401 && e.response?.data?.message) {
                errorMsg.value = e.response.data.message;
            } else {
                errorMsg.value = e.message;
            }
        } finally {
            processing.value = false;
        }
    }

    async function oauthLogin(provider: string, pid: string, redirectUrl = "") {
        errorMsg.value = "";
        try {
            const response = await axios.post(config.public.STATAMIC_HOST + `/oauth/${provider}/login`, {
                pid: pid,
            });

            if (response.status !== 200 || !response.data) {
                throw "Failed to login";
            }

            useUserStore().updateUser(response.data);

            if (redirectUrl) {
                navigateTo(redirectUrl);
                return;
            }

            navigateTo("/account/details");
        } catch (e: any) {
            console.log("e:", e);
            if (e.response?.status == 401 && e.response?.data?.message) {
                errorMsg.value = e.response.data.message;
            } else {
                errorMsg.value = e.message;
            }
        }
    }

    async function logout() {
        try {
            const response = await axios.post(config.public.STATAMIC_API + "/logout", {});

            if (response.status == 200) {
                useUserStore().removeUser();
            }
        } catch (e) {
            console.log(e);
        }
    }

    return { login, logout, oauthLogin, errorMsg, processing };
}
